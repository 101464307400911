<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="1600" v-if="detailData">
      <v-toolbar>
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn round outline flat @click="dialog = false">
          <span class="mx-2 subheading">Close</span>( <v-icon>close</v-icon>)
        </v-btn>
      </v-toolbar>

      <v-card>
        <div class="ml-5">
          <div class="logo-div">
            <img src="@/assets/nu_logos/nu_logo.png" class="logo" />
          </div>

          <div class="text-center">
            <p class="nu-title">National University, Bangladesh</p>
            <p class="exam-title">{{ detailData.exam_display_name }}</p>
            <h2 class="ml-5"><u>Result Sheet</u></h2>
          </div>

          <br />
          <table class="detail-info">
            <tr>
              <td class="pl-20">
                <b>Name of Student</b>
              </td>
              <td class="pl-20">
                <b>{{ detailData.student_name }}</b>
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                Father's Name
              </td>
              <td class="pl-20">
                {{ detailData.student_father_name }}
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                Mother's Name
              </td>
              <td class="pl-20">
                {{ detailData.student_mother_name }}
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                College
              </td>
              <td class="pl-20">
                {{ detailData.college_display_name }}
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                Roll No.
              </td>
              <td class="pl-20">
                {{ detailData.exam_roll }}
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                Registration No.
              </td>
              <td class="pl-20">
                {{ detailData.reg_no }}
              </td>
            </tr>
            <tr v-if="detailData.student_degree_code == 2">
              <td class="pl-20">
                Session
              </td>
              <td class="pl-20">
                {{ detailData.ac_session }}
              </td>
            </tr>
            <tr>
              <td class="pl-20">
                Student Type
              </td>
              <td class="pl-20">
                {{ detailData.student_type }}
              </td>
            </tr>

            <tr>
              <td class="pl-20">
                Result
              </td>
              <td class="pl-20">
                {{ detailData.result_type_name }}
              </td>
            </tr>
          </table>

          <br />
          <table
            class="detail-info"
            v-if="
              detailData.result_type == 1 ||
                detailData.result_type == 5 ||
                detailData.result_type == 2
            "
          >
            <tr>
              <th class="text-center" colspan="4">
                <h3 v-if="detailData.course_grade_title">
                  {{ detailData.course_grade_title }}
                </h3>
                <h3 v-else>Course wise Grade / Marks</h3>
              </th>
            </tr>
            <tr>
              <td class="pl-20">
                <b>Course Code</b>
              </td>
              <td class="pl-20">
                <b>Course Title</b>
              </td>
              <td class="text-center">
                <b>Credit</b>
              </td>

              <td class="text-center">
                <b>Ltr. Grade</b>
              </td>
            </tr>
            <tr v-for="(result, idx) in detailData.result_details" :key="idx">
              <td class="pl-20">
                {{ result.paper_code }}
              </td>
              <td class="pl-20">
                {{ result.paper_name }}
              </td>
              <td class="text-center">
                {{ result.credit }}
              </td>
              <td class="text-center">
                {{ result.letter_grade }}
              </td>
            </tr>
          </table>

          <div style="text-align: right">
            <div style="margin-right: 80px; padding-top: 20px">
              <span style="margin-right: 72px">sd/-</span><br /><span
                style="margin-right: 40px"
                >(Badruzzaman)</span
              ><br /><span>Controller of Examinations</span>
            </div>
          </div>
          <br />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogStatus", "detailData"],

  data: () => ({
    dialog: false,
    expand: false,
    loading: true,
  }),

  methods: {
    close() {
      this.$emit("update:dialogStatus", false);
      setTimeout(() => {
        this.$emit("update:indexEdited", -1);
      }, 300);
    },
  },

  watch: {
    dialogStatus(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (!val) this.close();
    },
  },
};
</script>

<style scoped>
.logo-div {
  /* width: 100% !important; */
  float: left;
  margin: 10px 0px 0px 5px;
}
.logo {
  width: 70px;
  height: 100px;
}
.nu-title {
  text-align: center;
  color: green;
  font-size: 30px;
  font-weight: bold;
}
.exam-title {
  font-size: 18px;
  font-weight: 600;
}
table.detail-info {
  border-collapse: collapse;
  width: 100%;
  padding-left: 5px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.detail-info th,
.detail-info td {
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0px 5px 0px;
  text-align: left;
  border: 1px solid #ddd;
}
.pl-20 {
  padding-left: 20px !important;
}
.ml-5 {
  margin: 0px 10px !important;
}
.font-bold {
  font-weight: bold;
}
.text-center {
  text-align: center !important;
}
.nowrap {
  white-space: nowrap;
}
</style>
